<template>
  <div class="input-group">
    <select
      ref="inputField"
      class="form-select"
      :disabled="!editable"
      v-model="lValue"
    >
      <option :value="null">---</option>
      <option v-for="option in list" :key="option.id" :value="option.id">
        {{ getName(option) }}
      </option>
    </select>
  </div>
</template>

<script>
import { API_GetPersonList } from "@/services/person";

export default {
  name: "personCombobox",
  props: {
    value: [Number, String],
    editable: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    API_GetPersonList().then((res) => {
      this.list = res;
    });
  },
  methods: {
    getName: (option) => {
      if (option.lastName || option.firstName || option.mName) {
        let _s = [];
        if (option.lastName) _s.push(option.lastName);
        if (option.firstName) _s.push(option.firstName);
        if (option.mName) _s.push(option.mName);
        return _s.join(" ");
      }
      return `Id пользователя: ${option.id}`;
    },
  },
  computed: {
    lValue: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-button {
  background-color: $main-1;
  color: white !important;
}
.edit-button {
  border-radius: $border-radius;
  background-color: $main-2;
  color: white !important;
}
.save-button {
  border-radius: $border-radius;
  background-color: $highlight;
  color: $main-3 !important;
}
.form-select:disabled {
  background-color: $main-2;
}
</style>
