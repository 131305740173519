var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('svg',{staticStyle:{"width":"0","height":"0"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg"}},[_c('filter',{attrs:{"id":"filters","x":"-10%","y":"-10%","width":"120%","height":"120%","filterUnits":"objectBoundingBox","primitiveUnits":"userSpaceOnUse","color-interpolation-filters":"sRGB"}},[_c('feColorMatrix',{attrs:{"type":"matrix","values":"1 0 0 0 0 1 0 0 0 0 1 0 0 0 0 0 0 0 1 0","in":"SourceGraphic","result":"colormatrix"}}),_c('feComponentTransfer',{attrs:{"in":"colormatrix","result":"componentTransfer"}},[_c('feFuncR',{attrs:{"type":"table","tableValues":"1 0.28 0.09"}}),_c('feFuncG',{attrs:{"type":"table","tableValues":"1 0.48 0.08"}}),_c('feFuncB',{attrs:{"type":"table","tableValues":"1 0.74 0.2"}}),_c('feFuncA',{attrs:{"type":"table","tableValues":"0 1"}})],1),_c('feBlend',{attrs:{"mode":"normal","in":"componentTransfer","in2":"SourceGraphic","result":"blend"}})],1)]),_c('yandex-map',{attrs:{"coords":_vm.centerMap,"options":{
      autoFitToViewport: 'always',
    }},on:{"click":_vm.onClick}},[_vm._l((_vm.assets),function(asset){return [(asset.type === '701')?[_c('ymap-marker',{key:asset.id,attrs:{"marker-id":asset.id,"coords":asset.points,"marker-type":"Polyline","options":{
            strokeColor: 'b4c045',
            strokeWidth: 3,
          }}})]:(asset.type === '901')?[_c('ymap-marker',{key:asset.id,attrs:{"marker-id":asset.id,"coords":asset.points[0],"marker-type":"Circle","circle-radius":2,"options":{
            fillColor: 'ffffff',
            strokeColor: 'b4c045',
          }}})]:_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }