<template>
  <div>
    <div class="d-flex justify-content-between">
      <h2>Создание заказа</h2>
      <div class="d-flex gap-3">
        <UIButton text="Сохранить" @click="createButton" leftIcon="bi-save" />
      </div>
    </div>
    <div class="row align-items-start my-4">
      <div class="col">
        <dl>
          <div class="mb-2 row">
            <dt class="col">Наименование:</dt>
            <dl class="col mb-0">
              <EditableInput v-model="orderInfo.title" editable />
            </dl>
          </div>
          <div class="mb-2 row">
            <dt class="col">Дата заявки:</dt>
            <dl class="col mb-0">
              <EditableInput
                v-model="orderInfo.createdAt"
                type="date"
                :editable="true"
              />
            </dl>
          </div>
          <div class="mb-2 row">
            <dt class="col">Вид заказа:</dt>
            <dl class="col mb-0">
              <EditableSelect
                v-model="orderInfo.kindId"
                :options="workKinds"
                editable
              />
            </dl>
          </div>
          <div class="mb-2 row">
            <dt class="col">Статус заказа:</dt>
            <dl class="col mb-0">
              <EditableSelect
                v-model="orderInfo.statusKindId"
                :options="workStatusKinds"
                editable
              />
            </dl>
          </div>
          <div class="mb-2 row">
            <dt class="col">Приоритет:</dt>
            <dl class="col mb-0">
              <EditableSelect
                v-model="orderInfo.priorityId"
                :options="priorityKinds"
                editable
              />
            </dl>
          </div>
          <div class="mb-2 row">
            <dt class="col">Ответственный:</dt>
            <dl class="col mb-0">
              <PersonCombobox v-model="orderInfo.issuerId" editable />
            </dl>
          </div>

          <div class="mb-2 row">
            <dt class="col">Дата начала:</dt>
            <dl class="col mb-0">
              <EditableInput
                v-model="orderInfo.dateWorkFrom"
                type="date"
                editable
              />
            </dl>
          </div>
          <div class="mb-2 row">
            <dt class="col">Дата окончания:</dt>
            <dl class="col mb-0">
              <EditableInput
                v-model="orderInfo.dateWorkTo"
                type="date"
                editable
              />
            </dl>
          </div>
          <div class="mb-2 row">
            <dt class="col">Объект:</dt>
            <dl class="col mb-0">
              <EditableSelectWithSearch
                v-model="orderInfo.locationId"
                :options="locationsList"
                editable
              />
            </dl>
          </div>
        </dl>
      </div>
      <div class="col">
        <Map :assets="locationAssets" />
      </div>
    </div>
  </div>
</template>

<script>
import UIButton from "@/components/UI/Button";
import PersonCombobox from "@/views/Overview/order/components/personCombobox";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";
import EditableSelectWithSearch from "@/components/UI/EditableSelectWithSearch";
import Map from "@/components/Map.vue";
import { API_work_create } from "@/services/work";
import { API_getKindByModelName } from "@/services/references/kind";
import { API_AssetContainers, API_getAssetContainer, API_PriorityEnum} from "@/services/api";

export default {
  name: "OrderCreate",
  components: {
    UIButton,
    PersonCombobox,
    EditableInput,
    EditableSelect,
    EditableSelectWithSearch,
    Map,
  },
  data() {
    return {
      orderInfo: {
        id: null,
        title: "",
        code: "",
        createdAt: new Date().toISOString().substring(0, 10),
        modifiedAt: "",
        dateWorkFrom: new Date().toISOString().substring(0, 10),
        dateWorkTo: "",
        kindId: null,
        statusKindId: 1,
        priorityId: null,
        locationId: null,
        authorId: null,
        issuerId: null,
        editorId: null,
        approverId: null,
      },
      workKinds: [],
      workStatusKinds: [],
      priorityKinds: null,
      assetContainers: [],
      locations: [],
    };
  },
  methods: {
    createButton: function () {
      API_work_create(this.orderInfo).then((res) => {
        this.$router.push({
          name: "Order view",
          params: {
            order: res.id,
          },
        });
      });
    },
    loadLocationAssets: function (assetContainerId) {
      API_getAssetContainer(assetContainerId).then((assetContainer) => {
        let locationAssetContainer = this.lodash.find(this.locations, {
          id: assetContainer.id,
        });
        if (assetContainer.locationId && typeof locationAssetContainer === "undefined") {
          this.locations.splice(assetContainer.locationId, 1, assetContainer);
        }
      });
    }
  },
  computed: {
    locationsList: function () {
      if (this.assetContainers.length > 0){
        return Array.from(this.assetContainers, (assetContainer) => ({
          id: assetContainer.locationId,
          value: assetContainer.name,
        }));
      }
      return null;
    },
    locationAssets: function () {
      if (this.orderInfo.locationId) {
        let orderLocationId = this.orderInfo.locationId;

        if (this.locations.length > 0) {
          let assetContainer = this.lodash.find(this.locations, {
            locationId: orderLocationId,
          });
          if (assetContainer){
            return assetContainer.assets ? assetContainer.assets : [];
          }
        }

        let assetContainer = this.lodash.find(this.assetContainers, {
          locationId: orderLocationId,
        });

        if (assetContainer){
          this.loadLocationAssets(assetContainer.id);
        }
      }

      return [];
    },
  },
  mounted() {
    API_getKindByModelName("WorkKind").then((res) => {
      this.workKinds = res;
    });
    API_getKindByModelName("WorkStatusKind").then((res) => {
      this.workStatusKinds = res;
    });
    API_PriorityEnum().then((res) => {
      this.priorityKinds = res;
    });
    API_AssetContainers().then((res) => {
      this.assetContainers = res;
    });
  },
};
</script>

<style lang="scss" scoped>
.map-holder {
  width: 500px;
  height: 300px;
  margin: auto;
}
.norm-calc {
  background-color: $main-1;
  color: white;
  border-radius: $border-radius;
}
</style>
