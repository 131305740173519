import axios from "@/plugins/axios";
import Vue from "vue";

/**
 * @return {ServerPerson[]}
 */
export const API_GetPersonList = async () => {
  return axios("/person?perPage=10000", {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      Vue.notify({
        type: "error",
        text: "Не удалось получить данные о пользователях",
      });
      throw error;
    });
};

/** Неопределенный объект
 * @typedef {object} ServerIdentifiedObject
 * @property {number} id Id объекта информационной модели
 * @property {string} name Название объекта информационной модели
 * @property {string} enproCode Шифр для некоторых объектов
 */

/** @typedef {object} ServerPerson
 * @property {number} id
 * @property {string} firstName Имя
 * @property {string} lastName Фамилия
 * @property {string} mName Отчество
 * @property {string} perfix Префикс
 * @property {string} suffix Суфикс
 * @property {ServerIdentifiedObject} IdentifiedObject
 * @property {string} landlinePhone Номер проводного телефона
 * @property {object} OldPerson
 * @property {number} OldPerson.id
 */
