import axios from "@/plugins/axios";

export const API_getKindByModelName = (modelName) => {
  return axios(`all_kind/model/${modelName}`, {
    method: "GET",
  })
    .then((response) => {
      return response.data.data.map((item) => {
        return {
          id: item.id,
          value: item.ru_value,
        };
      });
    })
    .catch((error) => {
      throw error;
    });
};
