<template>
  <div>
    <!-- Фильр для карты -->
    <svg
      style="width: 0; height: 0"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <filter
        id="filters"
        x="-10%"
        y="-10%"
        width="120%"
        height="120%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feColorMatrix
          type="matrix"
          values="1 0 0 0 0 1 0 0 0 0 1 0 0 0 0 0 0 0 1 0"
          in="SourceGraphic"
          result="colormatrix"
        />
        <feComponentTransfer in="colormatrix" result="componentTransfer">
          <feFuncR type="table" tableValues="1 0.28 0.09" />
          <feFuncG type="table" tableValues="1 0.48 0.08" />
          <feFuncB type="table" tableValues="1 0.74 0.2" />
          <feFuncA type="table" tableValues="0 1" />
        </feComponentTransfer>
        <feBlend
          mode="normal"
          in="componentTransfer"
          in2="SourceGraphic"
          result="blend"
        />
      </filter>
    </svg>

    <yandex-map
      :coords="centerMap"
      @click="onClick"
      :options="{
        autoFitToViewport: 'always',
      }"
    >
      <template v-for="asset in assets">
        <template v-if="asset.type === '701'">
          <ymap-marker
            :key="asset.id"
            :marker-id="asset.id"
            :coords="asset.points"
            marker-type="Polyline"
            :options="{
              strokeColor: 'b4c045',
              strokeWidth: 3,
            }"
          />
        </template>
        <template v-else-if="asset.type === '901'">
          <ymap-marker
            :key="asset.id"
            :marker-id="asset.id"
            :coords="asset.points[0]"
            marker-type="Circle"
            :circle-radius="2"
            :options="{
              fillColor: 'ffffff',
              strokeColor: 'b4c045',
            }"
          />
        </template>
      </template>
    </yandex-map>
  </div>
</template>

<script>
export default {
  name: "Map",
  props: {
    assets: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    centerMap() {
      if (this.assets.length > 0) {
        return this.assets[0].points[0];
      }
      return ["57.366771422829", "61.356153254976"];
    },
  },
  watch: {
    assets: function () {
      // map.container.fitToViewport();
      this.$forceUpdate();
    },
  },
  methods: {
    onClick(e) {
      this.coords = e.get("coords");
    },
  },
};
</script>

<style lang="scss">
.ymap-container {
  height: 600px;
}
[class*="ymaps-2"][class*="-ground-pane"] {
  filter: url(#filters);
}
</style>
