<template>
  <div :class="['dropdown', { float }]">
    <button
      class="btn dropdown-toggle w-100 text-start"
      type="button"
      data-bs-toggle="dropdown"
      :disabled="!editable"
      aria-expanded="false"
    >
      {{
        lValue
          ? lodash.find(options, (i) => i.id === lValue).value
          : "Не выбрано"
      }}
    </button>
    <div class="dropdown-menu mt-1 p-2 form-select">
      <div class="mb-3">
        <input
          v-model="filter"
          type="text"
          class="form-control border-1"
          placeholder="Найти по названию..."
        />
      </div>

      <div style="height: 150px" class="overflow-auto">
        <ul class="list-group list-group-flush">
          <template v-for="item in filteredValues">
            <li
              :key="item.id"
              @click="lValue = item.id"
              :class="['list-group-item', { active: lValue === item.id }]"
            >
              {{ item.value ? item.value : "Без названия" }}
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditableSelectWithSearch",
  props: {
    value: [Number, String],
    options: Array,
    editable: {
      type: Boolean,
      default: false,
    },
    float: Boolean,
  },
  data() {
    return {
      filter: "",
    };
  },
  computed: {
    lValue: {
      get: function () {
        if (this.options) {
          return this.value;
        }
        return null;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
    // eslint-disable-next-line vue/return-in-computed-property
    filteredValues: function () {
      return this.lodash.filter(this.options, (i) =>
        i.value.includes(this.filter)
      );
    },
    noneText: function () {
      if (this.options) {
        return " --- ";
      }
      return "Загрузка...";
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  border-radius: 6px;

  &.float {
    button {
      background-color: $main-2;
      &:disabled {
        background-color: $main-1;
        border: 2px solid $main-2;
      }
    }
    .dropdown-menu {
      background-color: $main-2;
    }
  }
  &:not(.float) {
    button {
      background-color: $main-1;
      &:disabled {
        background-color: $main-2;
        border: 2px solid $main-1;
      }
    }
    .dropdown-menu {
      background-color: $main-1;
    }
  }

  .list-group-item {
    background-color: $main-1;
    color: white;

    &.active {
      background-color: $main-2;
      border: 2px solid $main-1;
      border-radius: 6px;
    }
  }
}
</style>
